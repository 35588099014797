export const roles = {
    // 'Admin':'Admin',
    // 'User':'User',
    'Team member':'Team member',
    'New company':'New company',
}
export const grants={
    'all':'All',
    'iot':'IOT management',
    'products':'Products',
    'dispatch':'Dispatch',
    'farm':'Farm management'

}
export const userStatus={
    "Confirmed":true,
    "Wait for Approval":false,
    "true": "Confirmed",
    "false": "Wait for Approval"
}