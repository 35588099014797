<template>
    <div class="wrapper_main" style="min-height: 100dvh;">
        <main class="content" style="max-height: calc(100vh - 138px);">
            <div class="headline">
                <h1 class="headline__title">{{ isEdit ? $translate('edit') : $translate('add') }} {{ $translate('a-user') }}</h1>
                <p class="headline__text">{{ $translate('fill-in-information-to-add-new-user-to-team') }}</p>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('info')" :class="isActiveTab('info')"> {{ $translate('basic-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('location')" :class="isActiveTab('location')"> {{ $translate('location') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('device')" :class="isActiveTab('device')"> {{ $translate('assign-device') }} </a>
                    </li>
                    <!-- <li class="tabs__item">
                        <a class="nav-tab" @click="switchTab('rules')" :class="isActiveTab('rules')"> Rules </a>
                    </li> -->
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="info" class="tab-content__item" :class="isActiveTab('info')">
                        <div class="wizard-item">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title"> {{ $translate('name') }} </label>
                                        <input type="text" class="input" v-model="user.fullname" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title"> {{ $translate('email') }} </label>
                                        <input type="email" class="input" v-model="user.email" />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15">
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title"> {{ $translate('role') }} </label>
                                        <DropdownAdvanced :options="roles" v-model="user.role"></DropdownAdvanced>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15">
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="module__check">
											<input type="checkbox" v-model="addTemporalValidaty"/> 
											<span class="check"></span> 
											<span class="text">{{ $translate('add-a-temporal-validity') }}</span>
										</label>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards" v-if="addTemporalValidaty">
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title"> {{ $translate('period-start') }} </label>
                                        <DatePicker v-model="user.period_start"></DatePicker>
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form mt-15">
                                        <label class="el-form__title"> {{ $translate('period-end') }} </label>
                                        <DatePicker v-model="user.period_end"></DatePicker>
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards border-top mt-15 radio-buttons">
								<div class="cards__col-12">
                                    <div class="el-form mt-15">
										<label class="el-form__title"> {{ $translate('grants') }} </label>
                                    </div>
                                </div>
							
                                <div class="cards__col-4" v-for="(grant) in grants" :key="grant.id">
                                    <div class="el-form mt-15">
										 <div class="base-radio">
											<input v-model="user.grants" type="radio" :id="`pest_noticed-${grant.id}`" name="pest_noticed" class="base-radio__input" :value="grant.id"> 
											<label :for="`pest_noticed-${grant.id}`" class="base-radio__label">{{grant.name}}</label>
										</div>
                                    </div>
                                </div>
                               
                            </div>
                           
                        </div>
                    </div>
                    <div id="location" class="tab-content__item" :class="isActiveTab('location')">
                        <div class="tab-content__block footer-fixed">
                            <div class="map search-map" style="border-bottom-left-radius: var(--border-radius-7); border-bottom-right-radius: var(--border-radius-7); overflow: hidden">
                                <input type="text" style="z-index: 2" placeholder="USA, New York, New York" class="input bg-left search-icon" name="location" />
                                <div id="user-map" style="width: 100%; height: 410px"></div>
                            </div>
                        </div>
                    </div>
                    <div id="device" class="tab-content__item" :class="isActiveTab('device')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                    <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-product') }}</p>
                                </div>
                                <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="d in fieldValues('devices')" :key="d.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{ d.name }}</li>
                                            <li class="data-row__item">
                                                <span class="data-row__icon">
                                                    <DeviceIcon :category="d.category"></DeviceIcon>
                                                </span>
                                                {{ d.category }}
                                            </li>
                                            <!-- <li class="data-row__item">Italy, Rome</li> -->
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('devices', d.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <WhitePlusButton @click="addDeviceOpen">{{ $translate('add-device') }}</WhitePlusButton>
                        </div>
                    </div>
                    <!-- <div id="rules" class="tab-content__item" :class="isActiveTab('rules')">
                        <div class="addition footer-fixed">
                            <div class="headline-wrapper flex items-center">
                                <div class="headline headline--sm flex-auto">
                                    <h2 class="headline__title">Assign Rules</h2>
                                </div>
                                <WhitePlusButton @click="addRulesOpen">Add Rules</WhitePlusButton>
                            </div>
                            <ul class="editable-list">
                                <li class="editable-list__item" v-for="r in fieldValues('rules')" :key="r.id">
                                    <div class="data-row">
                                        <ul class="data-row__list">
                                            <li class="data-row__item">{{ r.name }}</li>
                                        </ul>
                                        <div class="data-row__controls">
                                            <button type="button" class="btn btn-icon red-color" @click="deleteFieldItem('rules', r.id)">
                                                <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <WhitePlusButton @click="addRulesOpen">Add Rules</WhitePlusButton>
                        </div>
                    </div> -->
                </div>
            </div>
        </main>


		<FooterTabsControl
			v-if="isEdit"
			
			:offNextButton="true"
            :previous="true"
            :confirmName="$translate('save')"
            @confirm="editUser"
            @swipeTab="swipeTab"
            @cancelAction="restoreFromBackup"

            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- before translate confirmName="Save" -->
        <FooterTabsControl
			v-else
            :previous="true"
            cancelRoute="/teams"
            :confirmName="$translate('add-a-user')"
            @confirm="addUser"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
            :processing="processing"
        />
        <!-- before translate confirmName="Add A User" -->
        <AddDevice v-if="addDevicesPopupInfo.show" :devices="addDevicesPopupInfo.devices" @close="addDeviceClose" @submit="addDeviceSubmit" title="Assign Device(s) to product" />
        <AddItems v-if="addRulesPopupInfo.show" :items="addRulesPopupInfo.rules" @close="addRulesClose" @submit="addRulesSubmit" :processing="aProcessing">
            <template v-slot:firstColumn="slotData">
                <span class="text">
                    {{ slotData.r.name }}
                </span>
            </template>
            <template v-slot:columns="slotData">
                <li class="data-row__item">
                    {{ slotData.r.id }}
                </li>
            </template>
        </AddItems>

        <AddItems v-if="addRulesPopupInfo.show" :items="addRulesPopupInfo.rules" @close="addRulesClose" @submit="addRulesSubmit" :processing="aProcessing">
            <template v-slot:firstColumn="slotData">
                <span class="text">
                    {{ slotData.r.name }}
                </span>
            </template>
            <template v-slot:columns="slotData">
                <li class="data-row__item">
                    {{ slotData.r.id }}
                </li>
            </template>
        </AddItems>
    </div>
</template>

<script>
import { API, Auth, Storage } from "aws-amplify";
import { createUser, updateCompany,updateUser } from "@/graphql/mutations";

import { getCompany,getUser } from "@/graphql/queries";
import { roles, grants, userStatus } from "@/config/teams.js";

import Base from "@/components/base";
import Buttons from "@/components/buttons";
import Popups from "@/components/popups";
import { compareData } from "@/helpers";
import { mixTabs, mixProcessing, mixArrayFields } from "@/mixins";
export default {
    name: "TeamsAdd",
    props:["id"],
	
    components: {
        ...Base,
        ...Buttons,
        ...Popups,
    },
    mixins: [mixTabs, mixProcessing, mixArrayFields],
    data() {
        return {
            user: {
                fullname: null,
                email: null,
                role: null,
                grants: null,
                period_start: null,
                period_end: null,
                status: userStatus[false],
                // status: 'active',
                // companyProductsId: null,
                location: {
                    lat: null,
                    lng: null,
                    address: null,
                },
            },

            pageTabs: ["info", "location", "device"],
            //config mixArrayFields
            arrayFields: {
                devices: [],
                rules: [],
                dispatches: [],
            },
            addDispatchesPopupInfo: {},
			addTemporalValidaty:false,
			backup:null,
        };
    },

    computed: {
        companyData() {
            return this.$store.state.companyData || {};
        },
        devices() {
            return this.$store.state.devices || [];
        },
        grants() {
            return Object.entries(grants).map(([key, value]) => {
                return { id: key, name: value };
            });
        },
        roles() {
            return Object.entries(roles).map(([key, value]) => {
                return { id: key, name: value };
            });
        },
		isEdit(){
            return !!this.id
        }
    },
    methods: {
        async initMap(lat,lng) {
            let payload = {
                markerType: "product",                
				lng:lng,
                lat:lat,
            };
            // this.$provider.gmap.setViewMode(true)
            this.$provider.gmap.createMap("user-map", payload, (res) => {
                this.setLocation(res);
            });
        },
        async setLocation(cords) {
            let query = Object.values(cords).join();
            const { data } = await this.$provider.positionStack.getReverse(query);
            if (data.length) {
                const { label, country, city, region } = data[0];
                // console.log(data[0]);
                let location = {
                    ...cords,
                    address: label,
                };
                this.user.location = location;
            }
        },
        makePassword() {
            var result = '';
            var characters = 'abcdefghijklmnopqrstuvwxyz';
            var charactersCaps = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var charactersNumber = '0123456789';
            var charactersLength = characters.length;

            for ( var i = 0; i < 4; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            for ( var i = 0; i < 4; i++ ) {
                result += charactersCaps.charAt(Math.floor(Math.random() * charactersLength));
            }

            for ( var i = 0; i < 2; i++ ) {
                result += charactersNumber.charAt(Math.floor(Math.random() * result.length));
            }

            result = result.split('').sort(function(){return 0.5-Math.random()}).join('')

            return result;
        },
        async addUser() {
            // this.processing = true;

            let userPassword = this.makePassword();

            let input = {
                ...this.user,
                teamID: this.companyData.id,
                // username: this.user.fullname,
            };
			if(!this.addTemporalValidaty){
				delete input.period_start
				delete input.period_end
			}

            let user = await Auth.signUp({
                username: this.user.email,
                password: userPassword,
                attributes: {
                    email: this.user.email,
                    'custom:full_name': this.user.fullname,
                    'custom:pl': ''+userPassword.length
                },
                autoSignIn: {
                    enabled: false
                }
            });

            input.username = user.user.username;
            if(this.user.role === "Team member"){
                input.userCompanyId = this.companyData.id;
            }

            input.temporary_password = userPassword;

            const result = await API.graphql({
                query: createUser,
                variables: {
                    input: input,
                },
            }).then((res) => res.data.createUser);

            let company = await API.graphql({
                query: getCompany,
                variables: {
                    id: this.companyData.id,
                },
            }).then((res) => res.data.getCompany);
            if (!company.team) {
                company.team = [];
            }
            company.team.push(result.id);
            const newCompany = await API.graphql({
                query: updateCompany,
                variables: {
                    input: {
                        id: this.companyData.id,
                        team: company.team,
                    },
                },
            }).then((res) => res.data.updateCompany);
            this.processing = false;
            const options = { title: "User Added", size: "sm", okLable: "To Teams" };
            await this.$dialogs.alert("", options).then((res) => {});
            this.$router.push("/teams");
        },

		async getUserInfo() {
            const user = await API.graphql({
                query: getUser,
                variables: {
                    id: this.id,
                },
            }).then((res) => res.data.getUser);
        
            this.setUserInfo(user)
            this.backup = JSON.parse(JSON.stringify(user));
        },
        
        setUserInfo(newUser){
            this.user={...newUser}
			this.addTemporalValidaty=!!(newUser.period_start||newUser.period_end)
        },

        restoreFromBackup() {
            // this.setUserInfo(this.backup)
            this.$router.push({path: '/teams'})
        },
		async editUser() {
			this.processing = true;
            // compare with
			
			let input = {
                ...this.user,
                username: this.user.fullname,
            };
			
            const update = compareData(this.user, this.backup,["location"]);
			if(!this.addTemporalValidaty){
				update.period_start=null
				update.period_end=null
			}
            console.log(update);
            await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: this.id,
                        ...update,
                    },
                },
            }).then((res) => res.data.updateProduct);


            await this.getUserInfo()
            
            this.processing = false;
            const options={title:'',size:'sm'} 
            await this.$dialogs.alert("User Saved", options).then(res=>{
            })
		},






    },
    // async created() {
    // 	this.product.companyProductsId = this.companyData.id;
    // },
    async mounted() {
        if(this.$route.name === 'TeamsEdit'){
            await this.getUserInfo();
        }

        await this.initMap();
    },
};
</script>
<style scoped>
.mt-15 {
    margin-top: 15px !important;
}
.radio-buttons{
	justify-content: start;
}
</style>
